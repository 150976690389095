.light {
  --text-color: var(--black);
  --link-color: var(--black);
  --bg-color: var(--white);
}

.neutral {
  --text-color: var(--black);
  --link-color: var(--black);
  --bg-color: var(--beige);
}

.dark {
  --text-color: var(--black);
  --link-color: var(--black);
  --bg-color: var(--white);
}

.wrapper {
  background: var(--bg-color);
  padding: var(--spacing-24) 0;
}

.container {
  --padding-container: var(--spacing-80);
  display: grid;
  grid-column: full-start / full-end !important;
  grid-template-columns: var(--grid-template-desktop);
}

.contentContainer {
  display: flex;
  flex-flow: column wrap;
  gap: var(--spacing-40);
  padding: var(--padding-container);
  grid-column: main-start / 8;
  color: var(--text-color);
}

.subtitle {
  position: relative;
  font: var(--font-h3-mobile-default);
  font-family: var(--font-wix-text);
  font-weight: 800;
  margin-left: 1.6rem;
  color: var(--sauge);
}

.subtitle::before {
  --size-icon: 12px;
  content: "";
  position: absolute;
  top: 50%;
  left: calc(var(--spacing-24) * -1);
  transform: translateY(-40%);
  height: var(--size-icon);
  width: var(--size-icon);
  background: var(--sauge);
  border-radius: 9rem;
}

.title {
  font: var(--font-h2-desktop-default);
  font-weight: 800;
}

.links {
  display: flex;
  flex-flow: column nowrap;
  gap: var(--spacing-8);
}

.links .link {
  color: var(--link-color);
  align-self: flex-start;
}

.links .link:hover {
  text-decoration: underline;
}

.links .link:active {
  text-decoration: none;
}

.linkText {
  font: var(--font-p1-desktop-default);
  font-weight: 800;
}

.text {
  font: var(--font-h3-mobile-default);
  font-weight: 800;
}

.links .link {
  color: var(--text-color);
  font: var(--font-p1-desktop-default);
  font-weight: 800;
  text-decoration: underline;
}

.imageContainer {
  max-height: 656px;
  grid-column: 8 / full-end;
  padding: var(--spacing-24);
  border-radius: var(--radius-m);
}

.imageContainer .image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--radius-m);
}

.button {
  display: flex;
  align-self: flex-start;
}

@media (max-width: 1024px) {
  .container {
    --padding-container: 0;
    padding: var(--padding-container);
    gap: 0;
    grid-template-columns: var(--grid-template-mobile);
  }
  .contentContainer {
    padding: var(--spacing-40) var(--spacing-24);
  }
  .title {
    font: var(--font-h1-mobile-default);
    font-weight: 800;
  }
  .text,
  .links .link {
    font: var(--font-p1-mobile-default);
    font-weight: 800;
  }
}
@media (max-width: 608px) {
  .container {
    --padding-container: 0;
  }
  .contentContainer {
    grid-column: main-start / main-end;
    padding: var(--spacing-24);
    gap: var(--spacing-24);
  }

  .links {
    gap: var(--spacing-16);
  }

  .imageContainer {
    grid-column: full-start / full-end;
  }
}
